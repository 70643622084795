class cookieCheckFS  {

    constructor(settings) {
        if(!settings) return;
        this.setupVariables(settings);

        if(this.cookieSet){
            this.updatePreferencesWithCookieData();
        }

        var thisclass = this;

        document.addEventListener('DOMContentLoaded', function(event){

            thisclass.privacyPageContainer = document.getElementById('cookie_policy_settings_container');

            if((!thisclass.cookieSet || thisclass.differenceInSettingsAndPreferences()) && !thisclass.privacyPageContainer){
                window.onload = function windowLoadCallbackModal(){
                    thisclass.showCookieModal();
                };
            } else if(thisclass.privacyPageContainer){
                 window.onload = function windowLoadCallbackContainer() {
                     thisclass.showPrivacyPageContent(thisclass.privacyPageContainer);

                 }
             }
        });
    }

    set settings(x){
        x.required = true;
        this._settings = x;
    }

    get settings(){
        return this._settings;
    }

    setupVariables(initSettings){
        this.settings = initSettings.settings;
        this.texts = initSettings.texts;
        this.privacypolicylink = initSettings.link;
        this.userPreferences = this.settings;
        this.cookieName = 'cookiePreferences';
        this.checkForCookie();
        this.modal = null;
        this.privacyPageContainer = false;
    }

    checkForCookie(){
        this.cookieSet = this.getCookieValue(this.cookieName) ? true : false;
        return this.cookieSet;
    }

    differenceInSettingsAndPreferences() {
        var cookie = this.getCookieValue(this.cookieName);
        var differenceFound = false;

        if (!cookie) return false;

        this.updatePreferencesWithCookieData();

        for (var category in this.settings) {

            if(
                typeof this.userPreferences[category] !== typeof this.settings[category]
                || this.userPreferences[category].length !== this.settings[category].length
            ){
                differenceFound = true;
                this.resetCategory(category);
                break;
            }


            if(typeof this.settings[category] === 'object'){

                this.settings[category].forEach(function (currentValue , index) {
                    if(currentValue.name === this.userPreferences[category].name){
                        differenceFound = true;
                        this.resetCategory(category);
                        return true;
                    }
                }, this);
            }
        }

        for (var category in this.userPreferences) {

            if(typeof this.userPreferences[category] === 'object'){
                this.userPreferences[category].forEach(function (currentValue , index) {
                    if(currentValue.name === this.settings[category].name){
                        differenceFound = true;
                        this.resetCategory(category);
                        return true;
                    }
                }, this);
            }
        }

        return differenceFound;

    }

    cookiesAllowed(string){

        return this.findUserPreferenceForService(string);
    }

    findUserPreferenceForCategory (string) {
        if(this.userPreferences === undefined) return false;

        var cookieCategory = string.split('.')[0].toLowerCase();
        var cookiesForCategoryAllowed = true;

        if(typeof this.userPreferences[cookieCategory] === 'boolean') return this.userPreferences[cookieCategory];


        this.userPreferences[cookieCategory].forEach(function(value, index, userPreferences){

            if(!value.cookiesAllowed) cookiesForCategoryAllowed = false;
        });

        return cookiesForCategoryAllowed;

    }

    findUserPreferenceForService (string) {

        if(this.userPreferences === undefined) return false;

        var cookieService = string.split('.');

        if(cookieService.length != 2) return false;

        if(!this.userPreferences[cookieService[0].toLowerCase()]) return false;

        var service = this.userPreferences[cookieService[0].toLowerCase()].filter(function(element){
            return element.name.toLowerCase() == cookieService[1].toLowerCase();
        })[0];

        if(!service) return false;

        return service.cookiesAllowed ? true : false;

    }

    updatePreferencesWithCookieData() {

        this.userPreferences = JSON.parse(this.getCookieValue(this.cookieName));

    }

    // main fun to handle display of cookie info modal
    showCookieModal() {
        this.modal = true;
        var modalHTML = this.loadMainHTML();

        var wholeModal = document.createElement('div');
        wholeModal.classList.add('cookie_container');

        wholeModal.appendChild(modalHTML);

        this.modal = wholeModal;
        this.showModal();
    }

    showPrivacyPageContent(containerElement){

        containerElement.appendChild(this.loadMainHTML());
    }

    loadMainHTML() {
        var classInstance = this;

        var modalWindow = document.createElement('div');
        modalWindow.classList.add('cookie_wrapper');
        modalWindow.classList.add('fadeIn');

        var modalHeader = document.createElement('div');
        modalHeader.classList.add('cookie_header');

        var modalHeaderHeadline = document.createElement('h2');
        modalHeaderHeadline.classList.add('cookie_header_ueberschrift');

        var modalHeaderHeadlineMainSpan = document.createElement('span');
        modalHeaderHeadlineMainSpan.classList.add('cookie_header_ueberschrift_wrapper');
        var modalHeaderHeadlineSubSpanBig = document.createElement('span');
        //var modalHeaderHeadlineSubSpanSmall = document.createElement('span');

        modalHeaderHeadlineSubSpanBig.classList.add('cookie_header_ueberschrift_big');
        modalHeaderHeadlineSubSpanBig.innerHTML = this.getText('headerHeadline');
        //modalHeaderHeadlineSubSpanSmall.classList.add('cookie_header_ueberschrift_small');
        //modalHeaderHeadlineSubSpanSmall.innerHTML = this.getText('headerSubline');

        modalHeaderHeadlineMainSpan.appendChild(modalHeaderHeadlineSubSpanBig);
        //modalHeaderHeadlineMainSpan.appendChild(modalHeaderHeadlineSubSpanSmall);
        modalHeaderHeadline.appendChild(modalHeaderHeadlineMainSpan);

        var modalHeaderText = document.createElement('p');
        modalHeaderText.classList.add('cookie_header_infotext');
        modalHeaderText.innerHTML = this.getText('headerParagraph');

        modalHeader.appendChild(modalHeaderHeadline);
        modalHeader.appendChild(modalHeaderText);
        modalWindow.appendChild(modalHeader);

        var modalContent = document.createElement('div');
        modalContent.classList.add('cookie_content');

        // todo: do a loop here ?
        if(this.userPreferences.required) {

            var modalCheckRequired = this.createCheckboxHTML('required');
            modalContent.appendChild(modalCheckRequired);

        }

        if(this.userPreferences.statistics) {

            var modalCheckStatistics = this.createCheckboxHTML('statistics');
            modalContent.appendChild(modalCheckStatistics);

        }

        if(this.userPreferences.external) {

            var modalCheckExternal = this.createCheckboxHTML('external');
            modalContent.appendChild(modalCheckExternal);

        }

        if(this.userPreferences.marketing) {

            var modalCheckMarketing = this.createCheckboxHTML('marketing');
            modalContent.appendChild(modalCheckMarketing);

        }

        modalWindow.appendChild(modalContent);

        var modalFooter = document.createElement('div');
        modalFooter.classList.add('cookie_footer');

        var modalUpperButtons = document.createElement('div');
        modalUpperButtons.classList.add('cookie_upper_buttons');
        modalFooter.appendChild(modalUpperButtons);

        var modalLowerButtons = document.createElement('div');
        modalLowerButtons.classList.add('cookie_lower_buttons');
        modalFooter.appendChild(modalLowerButtons);

        if(this.privacypolicylink && this.modal){

            var privacyPolicyLink = document.createElement('a');
            privacyPolicyLink.setAttribute('href' , this.privacypolicylink);
            privacyPolicyLink.classList.add('cookie_btn_text');
            privacyPolicyLink.innerHTML = this.getText('toPrivacyPage');
            modalLowerButtons.appendChild(privacyPolicyLink);
        }

        var modalDetailsButton = document.createElement('button');
        modalDetailsButton.classList.add('cookie_btn_text');
        modalDetailsButton.innerHTML = this.getText('details');
        modalDetailsButton.addEventListener('click', function(event) {

            var allDetails = document.querySelectorAll('.cookie_detail');

            if(!allDetails) return false;

            if(this.cookieDetailsShown){
                this.cookieDetailsShown = false;
                for (var i = 0; i < allDetails.length; i++) {
                    allDetails[i].classList.remove('active');
                }
            } else {
                this.cookieDetailsShown = true;
                for (var i = 0; i < allDetails.length; i++) {
                    allDetails[i].classList.add('active');
                }

            }

        });
        modalLowerButtons.appendChild(modalDetailsButton);

        var modalAcceptAllButton = document.createElement('button');
        modalAcceptAllButton.classList.add('cookie_btn_acceptAll');
        modalAcceptAllButton.innerHTML = this.getText('acceptAll');
        modalAcceptAllButton.addEventListener('click',
            function(event) {
                for (var category in classInstance.userPreferences){
                    if(category != 'required'){
                        classInstance.setUserPreferenceCategory(category, true);
                    }
                }
                classInstance.savePreferencesInCookie(180);
                classInstance.closeModalAndReload();
            });
        modalUpperButtons.appendChild(modalAcceptAllButton);

        var modalSavePreferencesButton = document.createElement('button');
        modalSavePreferencesButton.classList.add('cookie_btn_saveCheckbox');
        modalSavePreferencesButton.addEventListener('click', function(event) {
            classInstance.savePreferencesInCookie(2);
            classInstance.closeModalAndReload();
        });
        modalSavePreferencesButton.innerHTML = this.getText('saveChanges');
        modalUpperButtons.appendChild(modalSavePreferencesButton);

        modalWindow.appendChild(modalFooter);

        return modalWindow;

    }

    // function needs to be modified in case of super strict Cookie Law
    createCheckboxHTML(userPreferenceString){

        var checkContainer = document.createElement('div');
        checkContainer.classList.add('cookie_checkcontainer');

        var checkLabel = document.createElement('label');
        checkLabel.classList.add('checkbox_label');
        checkLabel.innerHTML = this.getText(userPreferenceString);

        if(userPreferenceString == 'required'){
            checkLabel.classList.add('checkbox_permaChecked');

        } else {

            var id = 'check_' + userPreferenceString;
            checkLabel.setAttribute('for', id );
            var currentState = this.findUserPreferenceForCategory(userPreferenceString);
            var checkInput = document.createElement('input');
            checkInput.setAttribute('id', id );
            checkInput.setAttribute('type', 'checkbox' );
            if(currentState) checkInput.setAttribute('checked', 'checked'  );
            checkInput.classList.add('checkbox_input');
            var classInstance = this;
            checkInput.addEventListener('change', function(event) { classInstance.toggleUserPreferenceCategory(event); })
            checkContainer.appendChild(checkInput);
        }

        checkContainer.appendChild(checkLabel);

        var checkDetail = document.createElement('p');
        checkDetail.classList.add('cookie_detail');
        checkDetail.innerHTML = this.getText(userPreferenceString + 'Paragraph');
        checkContainer.appendChild(checkDetail);

        return checkContainer;

    }

    getText (textIdentifier){

        this.fallbacktexts = {
            headerHeadline      : 'Diese Website verwendet Cookies',
            headerSubline       : 'Wir verwenden Cookies',
            headerParagraph     : 'Einige Cookies sind essenziell, während andere mir helfen, meine Website und deine Erfahrung zu verbessern.',
            required            : 'Notwendige Cookies',
            requiredParagraph   : 'Diese sind für die grundlegende und einwandfreie Funktion meiner Website erforderlich.',
            statistics          : 'Analyse',
            statisticsParagraph : 'Tracking Tools von Dritten ermöglichen die Analyse und Aufstellung von Statistiken.',
            marketing           : 'Marketing',
            marketingParagraph  : 'Marketing-Cookies werden von Drittanbietern oder Publishern verwendet, um Werbung zu personalisieren. Sie tun dies, indem sie Besucher über Websites hinweg verfolgen.',
            external            : 'Externe Medien',
            externalParagraph   : 'Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.',
            acceptAll           : 'Alle akzeptieren',
            denyAll             : 'Alle ablehnen',
            saveChanges         : 'Speichern',
            details             : 'Cookie-Details',
            toPrivacyPage       : 'Datenschutzerklärung',
            leaveWebsite        : 'Webseite verlassen',
            contentNotAllowed   : 'Dieser Inhalt kann auf Grund Ihrer getroffenen Cookie-Einstellungen nicht angezeigt werden.',
            allowCookiesForThisContent         : 'Klicken Sie hier um den Inhalt anzuzeigen und die Cookies zu akzeptieren',
        };

        if( typeof(this.texts) === 'object' && typeof(this.texts[textIdentifier]) !== 'undefined') return this.texts[textIdentifier];

        if( typeof(this.fallbacktexts[textIdentifier]) !== 'undefined') return this.fallbacktexts[textIdentifier];

        return '';
    }

    resetCategory(categoryname){
        if(!this.settings[categoryname]) return false;
        this.userPreferences[categoryname] =  this.settings[categoryname];
        return true;
    }

    toggleUserPreferenceCategory(event) {

        var cookieCategory = event.target.id.slice('check_'.length );
        var status = event.target.checked;

        for (var serviceindex in this.userPreferences[cookieCategory]) {

            this.userPreferences[cookieCategory][serviceindex].cookiesAllowed = status;

        }

    }

    setUserPreferenceService(category, service, setTo){
        if(!this.userPreferences[category]) return false;

        this.userPreferences[category].forEach(function (value) {
           if(value.name.toLowerCase() == service.toLowerCase()) value.cookiesAllowed = setTo;
        });
    }

    setUserPreferenceCategory(category, setTo){

        if(!this.userPreferences[category]) return false;

        this.userPreferences[category].forEach(function (value) {
            value.cookiesAllowed = setTo;
        });

        return true;
    }

    showModal(){

        if(this.modal) {
            document.body.appendChild(this.modal);
            return true;
        }

        return false;

    }

    checkAllowedAndCreateElement(serviceString , parentElementId, html, message){

        if(!serviceString || !parentElementId || !html) return false;

        var parent =  document.getElementById(parentElementId);

        if(!parent) return false;

        var categoryString = serviceString.split('.')[0];

        if(!categoryString) return false;

        var thisclass = this;

        message = message || this.getText('contentNotAllowed');

        if(this.cookiesAllowed(serviceString)){
            if(html.toLowerCase() != 'reload') {
                parent.innerHTML = html;
            }

        } else {

            var messagehtml = document.createElement('div');
            messagehtml.classList.add('cookies_accept_inactive_container');

            var messageText = document.createElement('p');

            messageText.classList.add('cookies_accept_cookies_text');
            messageText.innerHTML = message;
            var acceptButton = document.createElement('button');
            acceptButton.classList.add('cookies_accept_cookies_button');
            acceptButton.innerHTML = this.getText('allowCookiesForThisContent');
            acceptButton.addEventListener('click', function(){
                thisclass.setUserPreferenceCategory(categoryString,  true);
                thisclass.savePreferencesInCookie(100);
                parent.removeChild(messagehtml);
                if(html.toLowerCase() != 'reload') {
                    parent.innerHTML = html;
                }else {
                    location.reload();
                }
            });
            messageText.appendChild(acceptButton);
            messagehtml.appendChild(messageText);
            parent.appendChild(messagehtml);
        }
    }

    closeModalAndReload(){
        this.cookieDetailsShown = false;
        if(this.modal){
            this.modal.classList.remove('fadein');
        }
        setTimeout(function () {
            location.reload();
        }, 300 );

    }

    savePreferencesInCookie(daysToExpire = 30){
        var d = new Date();
        d.setTime(d.getTime() + (daysToExpire*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        // todo: maybe grab the full url instead of just "/" for the path
        document.cookie = this.cookieName + "=" + JSON.stringify(this.userPreferences) + ";" + expires + ";path=/";
    }

    getCookieValue(a) {
        var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
}

var cookieCheck = new cookieCheckFS(window.cookieSettings);
